<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">


                <div class="admin_main_block_left">
                    <div><router-link to="/Admin/jiazjy/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>
                    <div><el-input v-model="where.F_VR_IDENTIFY" placeholder="用户名称"></el-input></div>
                    <div style="width:160px;">
                        <el-select v-model="where.F_TI_STATUS" placeholder="选择类型">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="待审核" value="1"></el-option>
                            <el-option label="已通过" value="2"></el-option>
                            <el-option label="已拒绝" value="3"></el-option>
                        </el-select>
                    </div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>
                </div>


                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="50px"></el-table-column>
                    <el-table-column prop="F_VR_IDENTIFY" label="用户名称" width="70px"></el-table-column>
                    <el-table-column prop="F_TE_CONTENT" label="寄语内容"></el-table-column>
                    <el-table-column prop="F_VR_NICKNAME" label="微信昵称" width="100px"></el-table-column>

                    <el-table-column label="微信头像" width="70px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_HEADIMGURL||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                                <dd class="table_dl_dd_all">{{ scope.row.adv_title }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_VR_OPENID" label="openid" width="70px"></el-table-column>

                    <el-table-column prop="F_IN_CHUANGJSJ" label="创建时间" width="70px">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_IN_CHUANGJSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核状态" width="80px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_STATUS==2">审核通过</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.F_TI_STATUS==3">审核拒绝</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_STATUS==1">待审核</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <!--<el-button icon="el-icon-edit" :disabled="scope.row.F_TI_STATUS==2 || scope.row.F_TI_STATUS==3" @click="verify_click(scope.row.F_IN_ID)">审核</el-button>-->
                            <el-button icon="el-icon-edit"  @click="verify_click(scope.row.F_IN_ID)">审核</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                    <!--<el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'adv_form',params:{id:scope.row.id}})">编辑</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
              F_VR_IDENTIFY:'',
          },
      };
    },
    watch: {},
    computed: {},
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            this.where.page = this.current_page;
            this.$get(this.$api.getJiazjyList,this.where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.current_page = res.data.current_page;
                this.list = res.data.data;
            })

        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delJiazjy,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
        verify_click:function(F_IN_ID){
            this.$confirm('此操作将控制是否通过审核, 是否继续?', '提示', {
                confirmButtonText: '通过',
                cancelButtonText: '拒绝',
                type: 'info'
            }).then(() => {
                this.$post(this.$api.jiazjyVerifyChange,{F_IN_ID:F_IN_ID,F_TI_STATUS:2}).then(()=>{
                    this.$message.success('成功审核');
                    this.get_adv_list();
                });

            }).catch(() => {
                this.$post(this.$api.jiazjyVerifyChange,{F_IN_ID:F_IN_ID,F_TI_STATUS:3}).then(()=>{
                    this.$message.info('已拒绝');
                    this.get_adv_list();
                });

            });
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
</style>